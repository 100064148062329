<template>
  <div class="vertical-modal-inner">
    <button
      type="button"
      class="btn-close"
      @click="closeDialog(false)"
    ></button>
    <div class="vertical-modal wallet">
      <div class="vertical-inner">
        <div class="vertical_title-box">
          <div class="title-box">
            <h2>Wallet</h2>
          </div>
          <div class="vertical-tab">
            <v-tabs v-model="tab" background-color="transparent" vertical>
              <v-tab v-for="item in walletItems" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
        </div>
        <div class="vertical-tab-items">
          <v-tabs-items v-model="tab">
            <v-tab-item :key="walletItems.Deposit">
              <div class="tab-section d-flex">
                <v-tabs v-model="tabs" background-color="transparent">
                  <v-tab
                    v-for="item in walletTabs"
                    :key="item"
                    :disabled="item === 'Crypto' || item === 'CreditCard'"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </div>

              <div class="tabs-items">
                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="walletTabs.Fiat" v-if="isFiat">
                    <div class="wallet-section pt-0">
                      <v-btn class="pay-btn">
                        <img
                          src="@assets/img/login/bankname_sample0.png"
                          alt=""
                        />
                        <span>&#8361; 10,000,00 - &#8361;9,000,000,00</span>
                      </v-btn>
                    </div>

                    <v-form class="wallet-section form" ref="wallet_form">
                      <div class="form-group">
                        <h2>Sender's name</h2>
                        <v-text-field
                          v-model="sender"
                          required
                          outlined
                          hide-details
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <h2>Amount(KRW)</h2>
                        <v-text-field
                          v-model="sender"
                          placeholder="Enter 10,000.00 - 3,000,000.00"
                          required
                          outlined
                          hide-details
                        ></v-text-field>
                      </div>
                    </v-form>
                    <div class="wallet-section rate">
                      <div class="exchange-rate">
                        <h4 class="rate-title">Exchange rate</h4>
                        <p>1 USD = 1,296,89 KRW</p>
                      </div>
                      <div class="exchange-rate">
                        <h4 class="rate-title receive">Received amount</h4>
                        <p class="receive">= 0.00 USD</p>
                      </div>
                    </div>
                    <div class="deposite-button">
                      <v-btn elevation="0" @click="fiatChange">Deposit</v-btn>
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="walletTabs.Fiat" v-else>
                    <div class="select-section">
                      <v-select
                        :items="selectDollar"
                        v-model="dollar"
                        hide-details
                        solo
                      >
                        <template v-slot:selection="{ item }">
                          <v-list-item class="select-dollar">
                            <img
                              :src="require(`@assets/img/${item.image}`)"
                            />{{ item.name }}
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <img :src="require(`@assets/img/${item.image}`)" />{{
                            item.name
                          }}
                        </template>
                      </v-select>
                    </div>
                    <div class="wallet-section radio">
                      <div class="radio-inner">
                        <h2>Choose recharge channel</h2>
                        <div class="radio-pay">
                          <label>
                            <input
                              type="radio"
                              name="bank"
                              value="instawon_pay"
                              checked
                            />
                            <div class="pay-btn">
                              <img
                                src="@assets/img/login/bankname_sample0.png"
                              />
                              <span>&#8361; 10,000,00 - &#8361;9,000,00</span>
                            </div>
                          </label>
                          <label>
                            <input type="radio" name="bank" value="soju_pay" />
                            <div class="pay-btn">
                              <img src="@assets/img/bankname_sample1.png" />
                              <span>&#8361; 10,000,00 - &#8361;9,000,00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="wallet-section radio">
                      <div class="radio-inner">
                        <div class="bonus-title">
                          <h2>Channel bonus</h2>
                          <div class="decline-bonus">
                            <div class="circle">
                              <input
                                type="radio"
                                id="Decline_bonus"
                                name="decline"
                                checked
                              />
                              <label for="Decline_bonus">Decline bonus</label>
                              <div class="check"></div>
                            </div>
                          </div>
                        </div>
                        <div class="radio-pay">
                          <label>
                            <input
                              type="radio"
                              name="deposit"
                              value="15"
                              checked
                            />
                            <div class="pay-btn">
                              <div class="inner">
                                <p class="inner-title">Deposit Bonus 15%</p>
                                <p class="inner-result">
                                  needs : (deposit + bonus)*25
                                </p>
                              </div>
                            </div>
                          </label>
                          <label>
                            <input type="radio" name="deposit" value="100" />
                            <div class="pay-btn">
                              <div class="inner">
                                <p class="inner-title">100% 1st Deposit</p>
                                <p class="inner-result">
                                  needs : (deposit + bonus)*25
                                </p>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="deposite-button">
                      <v-btn elevation="0" @click="fiatChange">Deposit</v-btn>
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="walletTabs.Crypto"> </v-tab-item>
                  <v-tab-item :key="walletTabs.CreditCard"> </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>
            <v-tab-item :key="walletItems.Withdraw">
              <div class="tab-section d-flex">
                <v-tabs v-model="tabs" background-color="transparent">
                  <v-tab
                    v-for="item in walletTabs"
                    :key="item"
                    :disabled="item === 'Crypto' || item === 'CreditCard'"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </div>

              <div class="tabs-items">
                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="walletTabs.Fiat">
                    <div class="select-section">
                      <v-select
                        :items="selectDollar"
                        v-model="dollar"
                        hide-details
                        solo
                      >
                        <template v-slot:selection="{ item }">
                          <v-list-item class="select-dollar">
                            <img
                              :src="require(`@assets/img/${item.image}`)"
                            />{{ item.name }}
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <img :src="require(`@assets/img/${item.image}`)" />{{
                            item.name
                          }}
                        </template>
                      </v-select>
                    </div>
                    <section class="tab_item-section pt-2">
                      <div class="border-box separate">
                        <div class="separate-top">
                          <div class="allow_title-box">
                            <p class="allow-title">Allow extranction:</p>
                            <p class="number">₱ 0.74</p>
                          </div>
                        </div>
                        <hr class="separate-hr" />
                        <div class="separate-bottom">
                          <div class="allow-box">
                            <p class="allow-text">
                              Remaining required turnover: 0.00
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section class="tab_item-section">
                      <h2>Withdraw to</h2>
                      <div class="border-box add-bank">
                        <button class="add-icon"></button>
                        <p class="bank-text">Add bank card</p>
                      </div>
                    </section>
                    <section class="tab_item-section">
                      <h2>Amount (USD)</h2>
                      <div class="border-box form-group">
                        <v-text-field
                          v-model="mobileField"
                          required
                          placeholder="Please enter the USD withdrawal amount"
                          autocomplete="off"
                          hide-details
                        ></v-text-field>
                        <button class="send">Send</button>
                      </div>

                      <div class="border-box amount">
                        <div class="pay-amount">
                          <ul class="amount-lists">
                            <li class="amount-list">
                              <div class="amount-total">
                                <p class="amount-title">Fee:</p>
                                <p class="amount-result">0 KRW</p>
                              </div>
                            </li>
                            <li class="amount-list">
                              <div class="amount-total">
                                <p class="amount-title">Exchange rate</p>
                                <p class="amount-result">1 USD 1,296.56 KRW</p>
                              </div>
                            </li>
                            <li class="amount-list">
                              <div class="amount-total">
                                <p class="amount-title">Received amount</p>
                                <p class="amount-result">0 KRW</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>
                    <section class="tab_item-section">
                      <h2>Verification Code</h2>
                      <div class="border-box form-group">
                        <v-text-field
                          v-model="mobileField"
                          required
                          placeholder="Enter the Code"
                          autocomplete="off"
                          hide-details
                        ></v-text-field>
                        <button class="send">Send</button>
                      </div>
                    </section>
                    <div class="deposite-button">
                      <v-btn elevation="0">Withdraw</v-btn>
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="walletTabs.Crypto"> </v-tab-item>
                  <v-tab-item :key="walletTabs.CreditCard"> </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab: null,
      tabs: null,
      isFiat: false,
      walletItems: ["Deposit", "Withdraw"],
      walletTabs: ["Fiat", "Crypto", "CreditCard"],
      selectDollar: [
        {
          name: "KRW",
          image: "flag_kr.png"
        },
        {
          name: "USD",
          image: "flag_usa.png"
        }
      ],
      dollar: {
        name: "KRW",
        image: "flag_kr.png"
      },
      sender: "",
      mobileField: ""
    };
  },
  methods: {
    // 모달 닫기 이벤트 emit
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    },
    fiatChange() {
      this.isFiat = !this.isFiat;
    }
  }
};
</script>
